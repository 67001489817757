<template>
    <modal v-bind:isActive="open" v-on:close="() => this.closeModal()">
        <div slot="header">Confirm Cancel Delivery</div>
        <div slot="body">Are you sure you want to cancel this pickup order?</div>
        <div slot="footer">
            <a class="button is-danger"
                :class="cancelingDelivery && 'is-loading'" 
                v-on:click="() => cancelingDelivery ? false : cancelDelivery()"
            >
                Yes
            </a>
            <a class="button" 
                :disabled="cancelingDelivery" 
                v-on:click="() => cancelingDelivery ? false : this.closeModal()"
            >
                No
            </a>
        </div>
    </modal>
</template>

<script>
var modal = require("../modal.vue").default;

module.exports = {
    components: { modal },
    props: ['open', 'onClose', 'order'],
    data: function () {
        return {
            store_id: this.$store.state.user.store_id,
            info: [
            ],
            index: 0,
            cancelingDelivery: false
        };
    },
    created: function () {

    },
    computed: {
        orderToDelete: function () {
            return this.$store.state.orders.orderToDelete;
        },
        showDeleteModal: function () {
            return this.$store.state.orders.showDeleteModal;
        },
    },
    methods: {
        toHoursAndMinutes: function (date) {
            return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
        },
        cancelDelivery: function () {
            this.cancelingDelivery = true;
            this.$http.post(this.$store.state.order_url + `/ExternalOrder/CancelDelivery/${this.order.external_delivery_source}/${this.order.external_delivery_id}/${this.store_id}`)
            .then(
                () => {
                    this.cancelingDelivery = false;
                    // let updatedOrder = {
                    //     ...this.order,
                    //     // external_delivery_id: '',
                    //     // external_delivery_source: '',
                    //     external_delivery_status: 'canceling',
                    //     pending: false,
                    // };
                    // this.$store.dispatch("orders/saveEditOrder", updatedOrder);
                    this.closeModal();
                },
                (res) => {
                    alert(res?.body?.message)
                    this.cancelingDelivery = false;
                    console.log("Failed to get routes...", res);
                });
        },
        closeModal: function () {
            this.info = [];
            this.index = 0;
            this.cancelingDelivery = false;
            this.onClose();
        }
    },
};
</script>
      
<style scoped>
</style>