<template>
    <modal v-bind:isActive="open" v-on:close="() => this.closeModal()">
        
        <div slot="header" class="request-driver-header has-text-centered">
            <div v-if="order">
                <div class="container">
                    <div class="columns">
                        <div class="column address-column has-text-left column-title has-small-padding">
                            {{ order.customer_name }}
                        </div>
                        <div class="column column-title has-small-padding">
                            TIP
                        </div>
                        <div class="column column-title has-small-padding">
                            READY BY
                        </div>
                        <div class="column column-title has-small-padding">
                            DELIVER BY
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column address-column has-text-left has-small-padding">
                            {{ order.address }}
                        </div>
                        <div class="column has-small-padding">
                             ${{ formattedNumber(order.tip) }}
                        </div>
                        <div class="column has-small-padding">
                            {{ getReadyBy() }}
                        </div>
                        <div class="column has-small-padding">
                            {{  getDeliverBy() }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div slot="body">

            <div v-if="info.length > 0">
                <div 
                v-for="(item, index) in sortedInfo"
                :key="item.quote.quote_id"
                >
                    <div class="columns is-vcentered"
                        @click="toggleRowSelection(index)"
                        :class="{ 'selected-row': selectedRowIndex === index }">
                        <div class="column is-narrow">
                          <div class="center-image" style="display: flex; align-items: center; justify-content: center; height: 100%;">
                            <img class="logo-img" :src="getOrderSourceLogo((item.source).toLowerCase())" alt="Logo" />
                          </div>
                        </div>

                        <div class="column" v-if="item.quote.errorMessage == null">
                          <div class="columns">
                            <div class="column has-small-padding">
                              COST
                            </div>
                            <div class="column has-small-padding" v-if="includeTips">
                              TIP
                            </div>
                            <div class="column has-small-padding">
                              P U TIME
                            </div>
                            <div class="column has-small-padding">
                              DELIVERY
                            </div>
                            <div class="column has-small-padding">
                              TOTAL TIME
                            </div>
                          </div>

                          <div class="columns">
                            <div class="column has-small-padding">
                                <span :class="{ 'green-text': isCheaper(item) }">${{ formattedNumber(item.quote.fee) }}</span>
                            </div>
                            <div class="column has-small-padding" v-if="includeTips">
                              ${{ formattedNumber(order.tip) }}
                            </div>
                            <div class="column has-small-padding">
                              {{ item.quote.pickupTime }}
                            </div>
                            <div class="column has-small-padding">
                                <span :class="{ 'green-text': !isTimeBiggerThanDeliverByTime(item.quote.dropoffTime), 'red-text': isTimeBiggerThanDeliverByTime(item.quote.dropoffTime) }">
                                    {{ item.quote.dropoffTime }}
                                </span>
                            </div>
                            <div class="column has-small-padding">
                              {{ item.quote.deliveryTime }} mins
                            </div>
                          </div>
                        </div>

                        <div v-if="item.quote.errorMessage != null">
                            {{ item.quote.errorMessage  }}
                        </div>
                      </div>
                </div>
            </div>

            <div v-if="!this.quoteRequested">
                <div>
                    <div class="columns">
                        <div class="column bold-text">
                            INCLUDE TIP <input type="checkbox" v-model="includeTips" />
                        </div>
                        <div class="column">
                            <input class="input is-small" v-model="tipValue" type="number" />
                        </div>

                        <div class="column"></div>

                        <div class="column bold-text">
                            QUANTITY
                        </div>
                        <div class="column">
                            <input class="input is-small" v-model="quantity" type="number" />
                        </div>
                    </div>
                </div>
                
                <br/>

                <div>

                <div>
                    <!-- ORDER SIZE -->
                    <div class="columns">
                      <div class="column bold-text">ORDER SIZE</div>
                      <div
                        class="column clickable-column"
                        @click="toggleSelection('size', 'medium')"
                        :class="{ 'selected': selectedOption.size === 'medium' }"
                      >
                        MEDIUM
                      </div>
                      <div
                        class="column clickable-column"
                        @click="toggleSelection('size', 'large')"
                        :class="{ 'selected': selectedOption.size === 'large' }"
                      >
                        LARGE
                      </div>
                    </div>
                    <br>
                    <!-- SUCCESSFUL DELIVERY -->
                    <div class="columns">
                      <div class="column bold-text">SUCCESSFUL DELIVERY</div>
                      <div
                        class="column clickable-column"
                        @click="toggleSelection('deliverableAction', 'deliverable_action_meet_at_door')"
                        :class="{ 'selected': selectedOption.deliverableAction === 'deliverable_action_meet_at_door' }"
                      >
                        MEET AT DOOR
                      </div>
                      <div
                        class="column clickable-column"
                        @click="toggleSelection('deliverableAction', 'deliverable_action_leave_at_door')"
                        :class="{ 'selected': selectedOption.deliverableAction === 'deliverable_action_leave_at_door' }"
                      >
                        LEAVE AT DOOR
                      </div>
                    </div>
                    <br>
                    <!-- UNSUCCESSFUL DELIVERY -->
                    <div class="columns">
                      <div class="column bold-text">UNSUCCESSFUL DELIVERY</div>
                      <div
                        class="column clickable-column"
                        @click="toggleSelection('undeliverableAction', 'return')"
                        :class="{ 'selected': selectedOption.undeliverableAction === 'return' }"
                      >
                        RETURN
                      </div>
                      <div
                        class="column clickable-column"
                        @click="toggleSelection('undeliverableAction', 'leave_at_door')"
                        :class="{ 'selected': selectedOption.undeliverableAction === 'leave_at_door' }"
                      >
                        LEAVE AT DOOR
                      </div>
                    </div>
                  </div>
                    
                </div>

                <br>

                <div class="delivery-instructions">
                    <div class="delivery-instructions-bg has-text-centered">
                        <h3 class="title is-5 has-text-white">Delivery Instructions</h3>
                    </div>
                    <textarea
                        class="textarea has-text-black is-radiusless"
                        placeholder="Enter delivery instructions here..."
                        v-model="additionalDropoffNotes"
                        style="min-height: 6em;"
                    ></textarea>
                </div>

                <br>
                <div v-if="this.order && this.order.ScheduleType == 'SCHEDULED'">
                    <span>
                        {{
                            "This is a Scheduled Order, the pickup time will be set to: " + 
                            getScheduledDateString()
                        }}
                    </span>
                </div>
                <br v-if="this.order && this.order.ScheduleType == 'SCHEDULED'"/>

            </div>
            
            <div v-if="this.errorMessage">
                    <!-- <h1 v-if="this.serverError" style="font-size: 1.2rem; font-weight: bold">UBER DRIVER COULD NOT BE FOUND</h1> -->
                    <p v-for="(line, index) in errorMessage.split('\n')" :key="index">{{ line }}</p>
            </div>
        </div>

        <div slot="footer" class="footer-container">
            <div class="radio-inputs" v-if="!this.quoteRequested">
                <label class="radio">
                    <input type="checkbox" v-model="requestFromDoordash" @change="requestFromChanged"/>
                    <img class="logo-img" src="/img/orders/doordash-logo2.png" alt="DoorDash" />
                </label>

                <label class="radio">
                    <input type="checkbox" v-model="requestFromUber" @change="requestFromChanged"/>
                    <img class="logo-img" src="/img/orders/Uberats.png" alt="UberEats" />
                </label>
            </div>

            <!-- Buttons -->
            <div class="buttons">
                <a
                    class="button is-danger" 
                    v-on:click="() => this.closeModal()"
                >
                    Cancel
                </a>
                <a 
                    v-if="!this.quoteRequested"
                    class="button is-primary" 
                    v-on:click="() => requestDeliveryQuote()"
                >
                    Request
                </a>
                <a 
                    v-if="this.quoteRequested"
                    class="button is-primary" 
                    :class="((info.length == 0 || this.acceptingDelivery) && !errorMessage) && 'is-loading'" 
                    :disabled="Boolean(errorMessage)"
                    v-on:click="() => ((info.length != 0 && !this.acceptingDelivery) && !errorMessage) ? acceptDelivery() : false"
                >
                    Accept
                </a>
            </div>
        </div>

    </modal>
</template>

<script>
var modal = require("../modal.vue").default;
var timeFunctions = require("../../js/timeFunctions.js");
var orderFunctions = require("../../js/orderFunctions.js");

module.exports = {
    components: { modal },
    props: ['open', 'onClose', 'order', ],
    data: function () {
        return {
            store_id: this.$store.state.user.store_id,
            info: [],
            index: 0,
            acceptingDelivery: false,
            errorMessage: "",
            serverError: false,
            settings: this.$store.state.settings,
            quoteRequested: false,
            additionalDropoffNotes: "",
            quantity: 1,
            selectedQuote: null,
            includeTips: false,
            
            requestFromDoordash: true,
            requestFromUber: true,
            tipValue: 0,
            deliveryInstructions: "PLEASE CALL WHEN YOU ARRIVE AND LEAVE THE FOOD AT THE DOOR",
            selectedOption: {
                size: "medium",
                deliverableAction: "deliverable_action_meet_at_door",
                undeliverableAction: "leave_at_door",
            },
            requestFrom: [
                "Uber",
                "DoorDash"
            ],
            selectedRowIndex: 0,
            unselectedDeliveryOptions: [1, 2]
        };
    },
    created: function () {

    },
    computed: {
        orderToDelete: function () {
            return this.$store.state.orders.orderToDelete;
        },
        showDeleteModal: function () {
            return this.$store.state.orders.showDeleteModal;
        },
        storeInfo: function () {
            return this.$store.getters["orders/getStore"]
        },
        sortedInfo() {
            const hasError = this.info.some(item => item.quote.errorMessage !== null);

            if (hasError) {
                return this.info.slice().sort((a, b) => {
                    if (!a.quote.errorMessage && b.quote.errorMessage) {
                        return -1;
                    } else if (a.quote.errorMessage && !b.quote.errorMessage) {
                        return 1;
                    } else {
                        return 0; 
                    }
                });
            }

            return this.info.slice().sort((a, b) => {
                const isaBiggerThanDeliverByTime = this.isTimeBiggerThanDeliverByTime(a.quote.dropoffTime)
                const isbBiggerThanDeliverByTime = this.isTimeBiggerThanDeliverByTime(b.quote.dropoffTime)

                // Check if both come back with delivery time within deliver by time
                if (!isaBiggerThanDeliverByTime && !isbBiggerThanDeliverByTime) {
                    // Show the cheaper option uptop
                    return a.quote.fee - b.quote.fee;
                } else if (!isaBiggerThanDeliverByTime) {
                    // Show the on-time option as preferred
                    return -1;
                } else if (!isbBiggerThanDeliverByTime) {
                    // Show the on-time option as preferred
                    return 1;
                } else {
                    if(a.quote.deliveryTime > b.quote.deliveryTime) return 1;
                    return -1;
                }
            });
        },
    },
    methods: {
        extractTimestamp(dateString) {
            const match = dateString.match(/\/Date\((\d+)\)\//);
            if (match) {
                return parseInt(match[1], 10);
            }
            return NaN; // Invalid format
        },
        formatTimestamp(timestamp) {
            const date = new Date(timestamp);
            const hours = date.getHours();
            const minutes = date.getMinutes();
            const ampm = hours >= 12 ? "PM" : "AM";
            const formattedHours = hours % 12 || 12; // Convert 0 to 12 for AM

            const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, "0")} ${ampm}`;
            return formattedTime;
        },
        getReadyBy(){
            let readyBy
            if(this.order.is_ready_to_deliver){
                const is_ready_to_deliver_timestamp = this.extractTimestamp(this.order.is_ready_to_deliver_datetime);
                if (!isNaN(is_ready_to_deliver_timestamp)) {
                    readyBy = this.formatTimestamp(is_ready_to_deliver_timestamp);
                }
            }else{
                const entry_time_timestamp = this.extractTimestamp(this.order.entry_time);
                if (!isNaN(entry_time_timestamp)) {
                    const newTimestamp = entry_time_timestamp + this.order.prep_time * 60 * 1000;
                    readyBy = this.formatTimestamp(newTimestamp);
                }
            }
            return readyBy;
        },
        getDeliverBy() {
            const tgt = this.order.target_delivery_time_minutes + 10
            const entry_time_timestamp = this.extractTimestamp(this.order.entry_time);
            if (!isNaN(entry_time_timestamp)) {
                const newTimestamp = entry_time_timestamp + tgt * 60 * 1000;
                return this.formatTimestamp(newTimestamp);
            }
        },
        isCheaper(item) {
            // Check if this item's fee is less than the fees of all other items
            return this.sortedInfo.every((otherItem) => item.quote.fee <= otherItem.quote.fee);
        },
        getOrderSourceLogo: (source) => orderFunctions.getOrderSourceLogo(source),
        formattedNumber(number) {
            return number.toFixed(2);
        },
        getTime: (d) => timeFunctions.getTime(d, true),
        toHoursAndMinutes: function (date) {
            return 
            return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
        },
        getFees(quoteFees){
            return Number(quoteFees.replace("USD", ""));
        },
        hasEnoughCredits: function (quoteFees) {
            const fees = quoteFees
            const credits = this.storeInfo.credits;
            return fees <= credits;
        },
        acceptDelivery: function () {
            const info = this.sortedInfo[this.selectedRowIndex]
 
            this.acceptingDelivery = true;

            if(!this.hasEnoughCredits(info.quote.fee)){
                this.serverError = false
                this.errorMessage = "There are not enough credits in your store!"
                return;
            }

            let body = {
                "order_id": this.order.order_id,
                "order_number": this.order.order_number,
                "source_id": this.order.source_id,
                "quote_id": info.quote.quote_id,
                "size": this.selectedOption.size,
                "quantity": this.quantity,
                "dropoff_notes": this.additionalDropoffNotes,
                "deliverable_action": this.selectedOption.deliverableAction,
                "undeliverable_action": this.selectedOption.undeliverableAction,
                "fees": info.quote.fee,
                "store_id": this.store_id,
                "include_tips": this.includeTips,
                "tip": this.tipValue
            }

            this.$http.post(`${this.$store.state.order_url}/ExternalOrder/CreateDelivery/${info.source}`, body).then(
                (res) => {
                    let deliveryId = info.source == "Uber" ? res.data.id : info.quote.quote_id;
                    let updatedOrder = {
                        ...this.order,
                        external_delivery_id: deliveryId,
                        external_delivery_source: info.source,
                        external_delivery_status: 'pending',
                        entry_date: null,
                        entry_time: null,
                        OrderType: 'OutSourced',
                        ExternalEstimatedTimeToDeliver: res.data.dropoff_eta
                    };
                    this.$store.dispatch("orders/saveEditOrder", updatedOrder);
                    this.acceptingDelivery = false;
                    this.closeModal();
                },
                (res) => {
                    this.acceptingDelivery = false;
                    this.errorMessage = res.body.message != null ? res.body.message : res.body;
                    this.serverError = true;
                });
        },
        requestFromChanged: function() {
            const selectedOptions = [];
            if (this.requestFromUber) {
                selectedOptions.push('Uber');
            }
            if (this.requestFromDoordash) {
                selectedOptions.push('DoorDash');
            }
            this.requestFrom = selectedOptions
        },
        requestDeliveryQuote: function () {
            this.quoteRequested = true;                
            if (!this.order) {
                this.quoteRequested = false;
                return;
            }
            
            if(this.requestFrom.length == 0){
                alert("You should choose at least one source")
                this.quoteRequested = false;
                return;
            }

            //This body is used for DoorDash to request a quote.
            //Uber does not need this information in the request for a quote,
            //but it is used in the creation of the delivery.
            const payload = {
                "order_id": this.order.order_id,
                "quantity": this.quantity,
                "dropoff_notes": this.additionalDropoffNotes,
                "undeliverable_action": this.selectedOption.undeliverableAction,
                "store_id": this.store_id,
                "include_tips": this.includeTips,
                "tip": this.tipValue,
                "request_from": this.requestFrom
            }

            this.$http.post(`${this.$store.state.order_url}/ExternalOrder/CreateDeliveryQuote/`, payload).then(
                (res) => {
                    this.info = res.body.map(q => {
                        if(q.error_message == null){
                            const pickupTime = new Date();
                            pickupTime.setMinutes(pickupTime.getMinutes() + q.pickup_duration);

                            const dropoffTime = new Date();
                            dropoffTime.setMinutes(dropoffTime.getMinutes() + q.duration);

                            const entryTime = new Date(Number(this.order.entry_time.match(/\d+/)[0]));

                            const quote = {
                                quote_id: q.id,
                                pickupTime: this.getTime(pickupTime),
                                dropoffTime: this.getTime(dropoffTime),
                                deliveryTime: Math.floor((dropoffTime - entryTime) / 60000),
                                fee: q.fee / 100
                            };
                            this.unselectedDeliveryOptions.pop()
                            return { quote, source: q.source };
                        }else{
                            let quote;

                            try {
                                const error = JSON.parse(q.error_message);

                                if (Array.isArray(error.field_errors)) {
                                    const errorMessages = error.field_errors.map(errorObj => errorObj.error);
                                    const errorMessageString = errorMessages.join('\n');

                                    quote = {
                                        errorMessage: errorMessageString
                                    };
                                } else {
                                    quote = {
                                        errorMessage: "Validation Failed"
                                    };
                                }
                            } catch (e) {
                                quote = {
                                    errorMessage: "Validation Failed"
                                };
                            }

                            return { quote, source: q.source };
                        }
                    });

                    if(this.unselectedDeliveryOptions.length == 2) this.selectedRowIndex = -1;
                },
                (res) => {
                    this.selectedRowIndex = -1;
                    this.errorMessage = res.body.message;
                }
            );
        },
        getScheduledDateString: function () {
            if(this.order?.DeliverAt){
                let schedDate = new Date(new Date(parseInt(this.order.DeliverAt.substr(6))).getTime() + this.order.prep_time * 60 * 1000);
                
                let DD = String(schedDate.getDate()).padStart(2, '0');
                let MM = String(schedDate.getMonth() + 1).padStart(2, '0');
                let YYYY = schedDate.getFullYear();
                let hh = schedDate.getHours();
                let mm = schedDate.getMinutes();

                return `${MM}/${DD}/${YYYY} at ${hh}:${mm}`;
            }
            return "";
        },
        closeModal: function () {
            this.info = [];
            this.index = 0;
            this.acceptingDelivery= false;
            this.errorMessage = "";
            this.selectedOption.size = "medium";
            this.quoteRequested = false;
            this.selectedOption.deliverableAction = "deliverable_action_meet_at_door";
            this.selectedOption.undeliverableAction= "leave_at_door";
            this.additionalDropoffNotes = "";
            this.quantity = 1;
            this.onClose();
        },
        toggleSelection(section, option) {
            this.selectedOption[section] = option;
        },
        toggleRowSelection(index) {
            if(!this.unselectedDeliveryOptions.includes(index) && this.unselectedDeliveryOptions.length != 2)
                this.selectedRowIndex = index; 
        },
        isTimeBiggerThanDeliverByTime(a) {
            b = this.getDeliverBy()
            const parseTime = (time) => {
                const [hours, minutes] = time.match(/\d+/g).map(Number);
                const isPM = /pm/i.test(time);
                return isPM ? (hours % 12 + 12) * 60 + minutes : hours * 60 + minutes;
            };

            const timeA = parseTime(a);
            const timeB = parseTime(b);

            return timeA > timeB;
        }

    },
    watch: {
        order() {
            this.additionalDropoffNotes = this.order?.special_instructions;
            this.tipValue = this.order?.tip;
            // this.requestDeliveryQuote();
        },
    }
};
</script>
      
<style scoped>
.details-container {
    display: flex;
    flex-direction: column;
}

.details-column {
    display: flex;
}

.details-title {
    font-weight: bold;
    margin-inline-end: .5rem;
}

.column-title {
    font-size: 20px;
    font-weight: bold;
}

.bold-text{
    font-weight: bold;
}

.delivery-instructions-bg{
    background-color: #3273dc !important;
    padding: 0.3em
}

.logo-img{
    width: 2.5em;
    margin-left: 1em;
    margin-right: 1em;
}


.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.radio-inputs {
    flex: 1;
}

.buttons {
    margin-left: auto;
}

.selected {
  border: 2px solid #3273dc;
  color: #3273dc;
  font-weight: 400;
  cursor: pointer;
  border-radius: 5px;
}

.clickable-column {
  cursor: pointer;
}

.selected-row {
  position: relative;
  border: 4px solid #3273dc;
}

.selected-row::before,
.selected-row::after {
  content: '';
  position: absolute;
  top: -2px;
  bottom: -2px;
  width: 16px;
  background-color: #3273dc;
}

.selected-row::before {
  left: -2px;
}

.selected-row::after {
  right: -2px;
}

.green-text {
  color: green;
}

.red-text {
  color: red;
}
</style>