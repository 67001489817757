<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
<div class="card">
    <header class="card-header">
        <p class="card-header-title">Create New Order</p>
        <a class="card-header-icon button is-primary" v-on:click="saveOrder()">Save</a>
        <a class="card-header-icon button" v-on:click="toggleNewOrder()">Cancel</a>
    </header>

    <div class="card-content">
        <strong>
            <p class="help is-danger" v-bind:style="{ visibility: newOrderError ? 'visible' : 'hidden' }">
                {{ newOrderError }}
            </p>
        </strong>
        <section class="section">
            <div class="columns is-mobile top-reduce-height">
                <div class="column is-one-fifth">
                    <div class="field">
                        <label class="label newOrderFormattedPhoneLabel">Phone</label>
                        <p class="newOrderFormattedPhone">{{ formattedPhone }}</p>
                    </div>
                </div>

                <div class="column is-one-fifth">
                    <div class="field">
                        <label class="label">Order Number</label>
                        <div class="control">
                            <input ref="newOrderNumber" class="input is-medium" v-model="newOrder.orderNum" type="text" />
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorOrderNumber ? 'visible' : 'hidden',
                }">
                            Must be unique
                        </p>
                    </div>
                </div>

                <div class="column is-one-quarter">
                    <div v-if="!showDate" class="field double-field">
                        <label class="label">
                            Entry Date ({{ newOrder.entry_date | formatShortDate }})
                            <a class="button is-primary is-small change-date-button" v-on:click="showDate = !showDate" v-if="!newOrder.is_scheduled">Change</a>
                        </label>
                    </div>

                    <div v-if="showDate" class="field double-field">
                        <label class="label">
                            Entry Date
                            <a class="button is-primary is-small change-date-button" v-on:click="showDate = !showDate">Hide</a>
                        </label>
                        <div class="control">
                            <input class="input is-medium" v-model="newOrder.entry_date" type="date" />
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorEntryDate ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                    <div class="field">
                        <div class="control">
                            <input class="input is-medium" v-model="newOrder.entry_time" type="time"  :disabled="newOrder.is_scheduled" />
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorEntryTime ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                </div>
                <div class="column is-one-fifth">
                    <div class="field flex">
                        <label class="label">Scheduled?</label>
                        <div class="control">
                            <input type="checkbox" class="" id="isscheduled" v-model="newOrder.is_scheduled" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-mobile" v-if="newOrder.is_scheduled">
                <div class="column is-one-fifth">
                    <div class="field">
                        <label class="label">Scheduled Date</label>
                        <div class="control">
                            <input type="date" class="input is-medium" v-model="newOrder.deliver_date" />
                        </div>
                    </div>
                </div>

                <div class="column is-one-fifth">
                    <div class="field">
                        <label class="label">Scheduled Time</label>
                        <div class="control">
                            <input type="time" class="input is-medium" v-model="newOrder.deliver_time" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column is-full">
                    <div class="field">
                        <label class="label">Name</label>
                        <div class="control">
                            <input class="input is-medium" v-model="newOrder.name" type="text" />
                        </div>
                        <p class="help is-danger" v-bind:style="{ visibility: errorName ? 'visible' : 'hidden' }">
                            Required
                        </p>
                    </div>
                </div>
            </div>
            <div class="columns is-mobile">
                <div class="column is-full">
                    <div class="field">
                        <label class="label">Address</label>
                        <div class="control">
                            <address-textbox v-model="newOrder.address"></address-textbox>
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorAddress ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column is-full">
                    <div class="field">
                        <label class="label">Apartment, Dorm, or Special Instructions</label>
                        <div class="control">
                            <textarea rows="3" class="textarea is-medium" v-model="newOrder.special_instructions" type="text"></textarea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column is-one-third">
                    <div class="field">
                        <label class="label">Tip</label>
                        <div class="control">
                            <input type="number" class="input is-medium no-arrows" v-model="newOrder.tip" />
                        </div>
                    </div>
                </div>

                <div class="column is-one-third">
                    <div class="field">
                        <label class="label">Payment Type</label>
                        <div class="control">
                            <div class="select is-medium" style="width: 100%">
                                <select v-model="newOrder.payment" style="width: 100%">
                                    <option>Prepaid</option>
                                    <option>Cash</option>
                                    <option>Credit</option>
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorPayment ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                </div>

                <div class="column is-one-third">
                    <div class="field">
                        <label class="label">Total</label>
                        <div class="control">
                            <input class="input is-medium no-arrows" v-model="newOrder.total" type="number" />
                        </div>
                        <p class="help is-danger" v-bind:style="{ visibility: errorTotal ? 'visible' : 'hidden' }">
                            Required
                        </p>
                    </div>
                </div>
            </div>
           
        </section>
    </div>
</div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var addressTextbox = require("../address_textbox").default;

module.exports = {
    components: {
        addressTextbox,
    },
    created: function () {
        if (this.currentPanel === "newOrder") {
            let self = this;
            setTimeout(() => {
                self.$refs.newOrderNumber.focus();
            });
        }
    },
    data: function () {
        return {
            validationErrorOrderNumber: false,
            validationErrorEntryTime: false,
            validationErrorEntryDate: false,
            validationErrorName: false,
            validationErrorAddress: false,
            validationErrorPayment: false,
            validationErrorTotal: false,
            showDate: false,
            is_medium: false,
            is_large: false
        };
    },
    watch: {
        is_medium(newValue) {
            if (newValue) {
                this.is_large = false;
            }
        },
        is_large(newValue) {
            if (newValue) {
                this.is_medium = false;
            }
        }
    },
    computed: {
        settings: function() {
            return this.$store.getters["settings"];
        },
        newOrder() {
            var store = this.$store.state.orders.store;
            var newOrder = this.$store.state.orders.newOrder;
            return newOrder;
        },
        newOrderError() {
            return this.$store.state.orders.newOrderError;
        },
        formattedPhone() {
            var cleaned = ("" + this.newOrder.phone).replace(/\D/g, "");
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return "(" + match[1] + ") " + match[2] + "-" + match[3];
            }
            return this.newOrder.phone;
        },
        errorOrderNumber() {
            return this.validationErrorOrderNumber;
        },
        errorEntryDate() {
            return this.validationErrorEntryDate;
        },
        errorEntryTime() {
            return this.validationErrorEntryTime;
        },
        errorName() {
            return this.validationErrorName;
        },
        errorAddress() {
            return this.validationErrorAddress;
        },
        errorPayment() {
            return this.validationErrorPayment;
        },
        errorTotal() {
            return this.validationErrorTotal;
        },
        currentPanel() {
            return this.$store.state.app.currentPanel;
        },
        allOrders() {
            return this.$store.state.orders.all;
        }
    },
    methods: {
        setPanel: function (panel) {
            this.$store.dispatch("app/setPanel", panel);
        },
        toggleNewOrder: function () {
            this.validationErrorOrderNumber = false;
            this.validationErrorEntryDate = false;
            this.validationErrorEntryTime = false;
            this.validationErrorName = false;
            this.validationErrorAddress = false;
            this.validationErrorPayment = false;
            this.validationErrorTotal = false;

            this.setPanel("order");
            this.$store.dispatch("orders/clearNewOrder");
        },
        getTgt: function () {
            const radiusDriveTimes = this.settings.radius_drive_times;
            if(!radiusDriveTimes) return

            const parts = radiusDriveTimes.split("|");
            const minutes = parts[0].split(";");

            const tgt = parseInt(minutes[0]);

            return tgt;
        },
        getOrderToSave: function() {
            if(!this.newOrder.is_scheduled) {
                return this.newOrder;
            }

            const deliverAtDate = this.newOrder.deliver_date;
            const deliverAtTime = this.newOrder.deliver_time;
            
            const tgt = this.getTgt();

            let dateTimeStr = `${deliverAtDate}T${deliverAtTime}`;
            let dateTime = new Date(dateTimeStr);

            dateTime.setMinutes(dateTime.getMinutes() - parseInt(tgt));

            let newEntryDate = timeFunctions.formatDate(dateTime);
            let newEntryTime = dateTime.toTimeString().slice(0, 5);

            return {
                ...this.newOrder,
                entry_date: newEntryDate,
                entry_time: newEntryTime
            }
        },
        saveOrder: function () {
            this.validationErrorOrderNumber = false;
            this.validationErrorEntryDate = false;
            this.validationErrorEntryTime = false;
            this.validationErrorName = false;
            this.validationErrorAddress = false;
            this.validationErrorPayment = false;
            this.validationErrorTotal = false;

            if (this.newOrder.orderNum) {
                this.allOrders.forEach(o => {
                    if (o.order_number === parseInt(this.newOrder.orderNum)) this.validationErrorOrderNumber = true;
                })
            }
            if (!this.newOrder.entry_date) {
                this.validationErrorEntryDate = true;
            }
            if (!this.newOrder.entry_time) {
                this.validationErrorEntryTime = true;
            }
            if (!this.newOrder.name) {
                this.validationErrorName = true;
            }
            if (!this.newOrder.address) {
                this.validationErrorAddress = true;
            }
            if (!this.newOrder.payment) {
                this.validationErrorPayment = true;
            }
            if (!this.newOrder.total) {
                //this.validationErrorTotal = true;
            }

            if (
                this.validationErrorOrderNumber ||
                this.validationErrorEntryDate ||
                this.validationErrorEntryTime ||
                this.validationErrorName ||
                this.validationErrorAddress ||
                this.validationErrorPayment ||
                this.validationErrorTotal
            ) {
                return false;
            }

            const orderToSave = this.getOrderToSave();

            this.$store.dispatch("orders/saveNewOrder", orderToSave);
        },
    },
};
</script>

<style scoped>
.newOrderFormattedPhone {
    font-weight: 900;
    font-size: 1.3em;
}

.newOrderFormattedPhoneLabel {
    margin-bottom: 0.25em !important;
}

.change-date-button {
    font-size: 0.6rem !important;
}

.double-field {
    margin-bottom: .5em !important;
}

.no-arrows{
    -webkit-appearance: none;
    -moz-appearance: textfield;
}
.field.flex{
    display: flex;
    gap: 1em;
    align-items: center;
}
.top-reduce-height{
    height:6em;
}
</style>
