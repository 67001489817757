<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
<template>
<div class="card">
    <header class="card-header">
        <p class="card-header-title">Edit Order</p>
        <a class="card-header-icon button is-primary" v-if="!isReadOnly" @click="saveOrder" :class="{ 'is-loading': isOrderSaving }">
            <span v-if="!isOrderSaving">Save</span>
        </a>
        <a class="card-header-icon button" v-on:click="toggleEditOrder()">Cancel</a>
    </header>

    <div class="card-content">
        <strong>
            <p class="help is-danger" v-bind:style="{ visibility: editOrderError ? 'visible' : 'hidden' }">
                {{ editOrderError }}
            </p>
        </strong>
        <section class="section">
            <div class="columns is-mobile">
                <div class="column is-one-fifth">
                    <div class="field">
                        <label class="label">Phone Number</label>
                        <div class="control">
                            <input class="input is-medium" id="ontime-editphone-input" name="ontime-editphone-input" autocomplete="ontime-editphone-input" maxlength="10" v-model="editOrder.phone" type="text" :disabled="isReadOnly"/>
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorPhoneNumber ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                </div>

                <!-- <div class="column is-one-fifth">
                    <div class="field">
                        <label class="label">Extension</label>
                        <div class="control">
                            <input class="input is-medium" maxlength="10" v-model="editOrder.extension" type="text" :disabled="isReadOnly" />
                        </div>
                    </div>
                </div> -->

                <div class="column is-one-fifth">
                    <div class="field">
                        <label class="label">Order Number</label>
                        <div class="control">
                            <input ref="editOrderNumber" class="input is-medium" v-model="editOrder.order_number" type="text" :disabled="isReadOnly" />
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorOrderNumber ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                </div>

                <div class="column is-one-quarter">
                    <div v-if="!showDate" class="field double-field">
                        <label class="label">
                            Entry Date ({{ editOrder.entry_date | formatShortDate }})
                            <a v-if="!isReadOnly && !editOrder.is_scheduled" class="button is-primary is-small is-outlined change-date-button" v-on:click="showDate = !showDate">Change</a>
                        </label>
                    </div>

                    <div v-if="showDate" class="field double-field">
                        <label class="label">
                            Entry Date
                            <a class="button is-primary is-small is-outlined change-date-button" v-on:click="showDate = !showDate">Hide</a>
                        </label>
                        <div class="control">
                            <input class="input is-medium" v-model="editOrder.entry_date" type="date" :disabled="isReadOnly" />
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorEntryDate ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                    <div class="field">
                        <div class="control">
                            <input class="input is-medium" v-model="editOrder.entry_time" type="time" :disabled="isReadOnly || editOrder.is_scheduled"/>
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorEntryTime ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                </div>
                <div class="column is-one-fifth">
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                <input type="checkbox" class="" id="isscheduled" v-model="editOrder.is_scheduled" :disabled="isReadOnly"/>
                                Scheduled?
                            </label>
                        </div>
                    </div>
                    <div class="field">
                        <div class="control">
                            <label class="label">
                                <input type="checkbox" class="" id="ignorePrepTime" v-model="editOrder.ignore_prep_time" :disabled="isReadOnly"/>
                                Ignore Prep Time?
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column is-one-fifth">
                    <div class="field" v-if="editOrder.is_scheduled">
                        <label class="label">Scheduled Date</label>
                        <div class="control">
                            <input type="date" class="input is-medium" v-model="editOrder.deliver_at" :disabled="isReadOnly" />
                        </div>
                    </div>
                </div>

                <div class="column is-one-fifth">
                    <div class="field" v-if="editOrder.is_scheduled">
                        <label class="label">Scheduled Time</label>
                        <div class="control">
                            <input type="time" class="input is-medium" v-model="editOrder.deliver_time" :disabled="isReadOnly"/>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column is-full">
                    <div class="field">
                        <label class="label">Name</label>
                        <div class="control">
                            <input class="input is-medium" v-model="editOrder.customer_name" type="text" :disabled="isReadOnly"/>
                        </div>
                        <p class="help is-danger" v-bind:style="{ visibility: errorName ? 'visible' : 'hidden' }">
                            Required
                        </p>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column is-full">
                    <div class="field">
                        <label class="label">Address</label>
                        <div class="control">
                            <address-textbox v-model="editOrder.address" :disabled="isReadOnly || (editOrder.OrderType != null && editOrder.OrderType === 'Pickup')"></address-textbox>
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorAddress ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                </div>
            </div>

            <div class="columns is-mobile">
                <div class="column is-full">
                    <div class="field">
                        <label class="label">Apartment, Dorm, or Special Instructions</label>
                        <div class="control">
                            <textarea rows="3" class="textarea is-medium" v-model="editOrder.special_instructions" type="text" :disabled="isReadOnly"></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="columns is-mobile">
                <div class="column is-one-third">
                    <div class="field">
                        <label class="label">Tip</label>
                        <div class="control">
                            <input type="number" class="input is-medium" v-model="editOrder.tip" min="0.00" step="0.01" max="2500" :disabled="isReadOnly"/>
                        </div>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="field">
                        <label class="label">Payment Type</label>
                        <div class="control">
                            <div class="select is-medium" style="width: 100%">
                                <select v-model="editOrder.payment" style="width: 100%" :disabled="isReadOnly">
                                    <option>Prepaid</option>
                                    <option>Cash</option>
                                    <option>Credit</option>
                                    <option>Other</option>
                                </select>
                            </div>
                        </div>
                        <p class="help is-danger" v-bind:style="{
                  visibility: errorPayment ? 'visible' : 'hidden',
                }">
                            Required
                        </p>
                    </div>
                </div>
                <div class="column is-one-third">
                    <div class="field">
                        <label class="label">Total</label>
                        <div class="control">
                            <input class="input is-medium" v-model="editOrder.total" type="number" min="0.01" step="0.01" max="2500" :disabled="isReadOnly" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
</template>

<script>
var timeFunctions = require("../../js/timeFunctions.js");
var addressTextbox = require("../address_textbox").default;

module.exports = {
    components: {
        addressTextbox,
    },
    created: function () {
        if (this.currentPanel === "editOrder") {
            let self = this;
            setTimeout(() => {
                self.$refs.editOrderNumber.focus();
            });
        }
    },
    data: function () {
        return {
            validationErrorPhoneNumber: false,
            validationErrorOrderNumber: false,
            validationErrorEntryDate: false,
            validationErrorEntryTime: false,
            validationErrorName: false,
            validationErrorAddress: false,
            validationErrorPayment: false,
            validationErrorTotal: false,
            showDate: false,
        };
    },
    computed: {
        settings: function() {
            return this.$store.getters["settings"];
        },
        isReadOnly: function() {
            return this.$store.getters["app/getEditOrderScreenReadOnly"]
        },
        editOrder() {
            return  this.$store.state.orders.editOrder;
        },
        isOrderSaving(){
            return this.$store.state.orders.isOrderSaving;
        },
        editOrderError() {
            return this.$store.state.orders.editOrderError;
        },
        errorPhoneNumber() {
            return this.validationErrorPhoneNumber;
        },
        errorOrderNumber() {
            return this.validationErrorOrderNumber;
        },
        errorEntryDate() {
            return this.validationErrorEntryDate;
        },
        errorEntryTime() {
            return this.validationErrorEntryTime;
        },
        errorName() {
            return this.validationErrorName;
        },
        errorAddress() {
            return this.validationErrorAddress;
        },
        errorPayment() {
            return this.validationErrorPayment;
        },
        currentPanel() {
            return this.$store.state.app.currentPanel;
        },
    },
    methods: {
        setPanel: function (panel) {
            this.$store.dispatch("app/setPanel", panel);
        },
        toggleEditOrder: function () {
            this.validationErrorPhoneNumber = false;
            this.validationErrorOrderNumber = false;
            this.validationErrorEntryDate = false;
            this.validationErrorEntryTime = false;
            this.validationErrorName = false;
            this.validationErrorAddress = false;
            this.validationErrorPayment = false;
            this.setPanel("order");
        },
        saveOrder: function () {
            this.validationErrorPhoneNumber = false;
            this.validationErrorOrderNumber = false;
            this.validationErrorEntryDate = false;
            this.validationErrorEntryTime = false;
            this.validationErrorName = false;
            this.validationErrorAddress = false;
            this.validationErrorPayment = false;

            if (!this.editOrder.phone || this.editOrder.phone.length != 10) {
                this.validationErrorPhoneNumber = true;
            }
            if (!this.editOrder.order_number) {
                this.validationErrorOrderNumber = true;
            }
            if (!this.editOrder.entry_date) {
                this.validationErrorEntryDate = true;
            }
            if (!this.editOrder.entry_time) {
                this.validationErrorEntryTime = true;
            }
            if (!this.editOrder.customer_name) {
                this.validationErrorName = true;
            }
            if (!this.editOrder.address && (this.editOrder.OrderType != null && this.editOrder.OrderType != 'Pickup')) {
                this.validationErrorAddress = true;
            }
            if (!this.editOrder.payment) {
                this.validationErrorPayment = true;
            }

            if (
                this.validationErrorPhoneNumber ||
                this.validationErrorOrderNumber ||
                this.validationErrorEntryDate ||
                this.validationErrorEntryTime ||
                this.validationErrorName ||
                this.validationErrorAddress ||
                this.validationErrorPayment 
            ) {
                return false;
            }

            
            const orderToSave = this.getOrderToSave();

            this.$store.dispatch("orders/saveEditOrder", orderToSave);
        },
        getTgt: function () {
            const radiusDriveTimes = this.settings.radius_drive_times;
            if(!radiusDriveTimes) return

            const parts = radiusDriveTimes.split("|");
            const minutes = parts[0].split(";");

            const tgt = parseInt(minutes[0]);

            return tgt;
        },
        getOrderToSave: function() {
            if(!this.editOrder.is_scheduled) {
                return this.editOrder;
            }

            const deliverAtDate = this.editOrder.deliver_at;
            const deliverAtTime = this.editOrder.deliver_time;
            
            const tgt = this.getTgt();

            let dateTimeStr = `${deliverAtDate}T${deliverAtTime}`;
            let dateTime = new Date(dateTimeStr);

            dateTime.setMinutes(dateTime.getMinutes() - parseInt(tgt));

            let newEntryDate = timeFunctions.formatDate(dateTime);
            let newEntryTime = dateTime.toTimeString().slice(0, 5);

            return {
                ...this.editOrder,
                entry_date: newEntryDate,
                entry_time: newEntryTime
            }
        },
    },
};
</script>

<style scoped>
.change-date-button {
    font-size: 0.6rem !important;
}

.double-field {
    margin-bottom: .5em !important;
}

.button.is-loading::after {
    border-color: #209cee transparent transparent transparent;
  }
</style>
