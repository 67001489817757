<template>
    <modal
      v-bind:isActive="showPromptModal"
      v-on:close="togglePromptModal()"
      class="prompt-wrap"
    >
      <div class="prompt-header" slot="header">{{ headerText }}</div>
      <div class="prompt-body" slot="body">{{ bodyText }}</div>
      <div class="prompt-footer" slot="footer">
        <a v-for="(button, index) in buttons" :key="index" :class="button.class" v-if="isVisible(button)" @click="button.action">{{ button.text }}</a>
        <a class="button is-danger" v-if="modalType === 'OPEN_PICKUP_ORDERS'" v-on:click="togglePromptModal()">Cancel</a>
        <a class="button mr-1" v-if="modalType === 'OPEN_PICKUP_ORDERS'" v-on:click="closeStore()">Leave Orders Open</a>
        <a class="button mr-1" v-if="modalType === 'OPEN_PICKUP_ORDERS'" v-on:click="markPickupOrdersComplete()">Mark Orders Complete</a>

        <a class="button" v-if="modalType === 'DELETE_ORDER'" v-on:click="togglePromptModal()">Cancel</a>
        <a class="button mr-1 is-danger" v-if="modalType === 'DELETE_ORDER'" v-on:click="confirmPrompt()">Delete Order</a>
        <a class="button mr-1 is-warning" v-if="modalType === 'DELETE_ORDER'" v-on:click="markOrderAsVoid()">Set it as VOID</a>
      </div>
    </modal>
  </template>
          
  <script>
  var modal = require("./modal.vue").default;

  module.exports = {
    components: { modal },
    data: function () {
      return {};
    },
    created: function () {},
    computed: {
        buttons: function () {
            return [
                {
                    text: "No",
                    class: "button",
                    conditions: [
                        "OPEN_PICKUP_ORDERS",
                        "DAY_CLOSED",
                        "CLOSE_DAY_ERROR",
                        "CLOSE_DAY_OUTSTANDING_BALANCE",
                        "CLOSE_DAY_DRIVER_LEFT",
                        "NO_DRIVER_SELECTED",
                        "NOT_READY",
                        "NO_ORDERS_SELECTED",
                        "NO_ORDER_SELECTED_FOR_VIRTUAL_DRIVERS",
                        "MANY_ORDERS_SELECTED_FOR_VIRTUAL_DRIVERS",
                        "DELETE_ORDER"
                    ],
                    action: this.togglePromptModal,
                },
                {
                    text: "Yes",
                    class: "button is-danger",
                    conditions: [
                        "OPEN_PICKUP_ORDERS",
                        "DAY_CLOSED",
                        "CLOSE_DAY_ERROR",
                        "CLOSE_DAY_OUTSTANDING_BALANCE",
                        "CLOSE_DAY_DRIVER_LEFT",
                        "NO_DRIVER_SELECTED",
                        "NOT_READY",
                        "NO_ORDERS_SELECTED",
                        "NO_ORDER_SELECTED_FOR_VIRTUAL_DRIVERS",
                        "MANY_ORDERS_SELECTED_FOR_VIRTUAL_DRIVERS",
                        "DELETE_ORDER"
                    ],
                    action: this.confirmPrompt,
                },
                {
                    text: "Ok",
                    class: "button",
                    conditions: [
                        "DAY_CLOSED",
                        "CLOSE_DAY_ERROR",
                        "CLOSE_DAY_OUTSTANDING_BALANCE",
                        "CLOSE_DAY_DRIVER_LEFT",
                        "NO_DRIVER_SELECTED",
                        "NOT_READY",
                        "NO_ORDERS_SELECTED",
                        "NO_ORDER_SELECTED_FOR_VIRTUAL_DRIVERS",
                        "MANY_ORDERS_SELECTED_FOR_VIRTUAL_DRIVERS",
                    ],
                    action: this.togglePromptModal,
                },
            ];
        },
        showPromptModal: function () {
            return this.$store.state.app.showPromptModal && this.$store.state.app.modalType != 'DAY_OPEN'
        },
        modalType: function () {
            return this.$store.state.app.modalType;
        },
        headerText: function () {
            //remove route
            if (this.modalType === 'REMOVE_ROUTE'){
                return 'Confirm Remove Route'
            }

            //unassign orders from driver on road
            if (this.modalType === 'UNASSIGN_ORDER'){
                return 'Confirm Unassign Order'
            }

            //assign orders on the road to driver
            if (this.modalType === 'ASSIGN_ORDER_LEFT'){
                return 'Confirm Assign Order'
            }

            if (this.modalType === 'ASSIGN_ORDER_DRIVER_LEFT'){
                return 'Confirm Assign Order'
            }

            if (this.modalType === 'DELETE_ORDER'){
                return 'Confirm Action'
            }

            if (this.modalType === 'NOT_OPEN'){
                return 'Store Not Open'
            }

            if (this.modalType === 'NOT_READY'){
                return 'Order(s) Not Ready'
            }

            if (this.modalType === 'DELETE_DRIVER'){
                return 'Confirm Delete Driver'
            }

            if (this.modalType === 'REMOVE_DRIVER'){
                return 'Confirm Remove Driver'
            }

            if (this.modalType === 'CLOSE_DAY'){
                return 'Close Account'
            }

            if (this.modalType === 'CLOSE_DAY_ERROR' || this.modalType === 'CLOSE_DAY_DRIVER_LEFT' || this.modalType == 'CLOSE_DAY_OUTSTANDING_BALANCE'){
                return 'Can not close store'
            }

            if (this.modalType === 'CLOSE_DRIVER'){
                return 'Close Driver'
            }

            if (this.modalType === 'NO_DRIVER_SELECTED'){
                return 'No Driver Selected'
            }

            if (this.modalType === 'NOT_ALL_ORDERS_ARE_DELIVERED'){
                return 'Not all orders are delivered'
            }

            if (this.modalType === 'NO_ORDERS_SELECTED'){
                return 'No orders selected'
            }

            if (this.modalType === 'OPEN_PICKUP_ORDERS'){
                return 'Close Account'
            }

            if (this.modalType === 'NO_ORDER_SELECTED_FOR_VIRTUAL_DRIVERS'){
                return 'No order selected'
            }

            if (this.modalType === 'MANY_ORDERS_SELECTED_FOR_VIRTUAL_DRIVERS'){
                return 'Select one order'
            }
            
        },
        bodyText: function () {
            if (this.modalType === 'REMOVE_ROUTE'){
                return 'You are about to remove a route from a driver, would you like to proceed?'
            }

            if (this.modalType === 'UNASSIGN_ORDER'){
                return 'Orders being uassigned from the driver may already be on the road, would you like to proceed?'
            }

            if (this.modalType === 'ASSIGN_ORDER_LEFT'){
                return 'Orders being assigned to the driver may already be on the road, would you like to proceed?'
            }

            if (this.modalType === 'ASSIGN_ORDER_DRIVER_LEFT'){
                return 'Driver may be already on the road, do you wish to continue assigning the order(s)?'
            }

            if (this.modalType === 'DELETE_ORDER'){
                return 'Do you want to delete the order permanently or set it as void?'
            }

            if (this.modalType === 'NOT_OPEN'){
                return 'Unable to assign, the store is currently not open. Would you like to go to the Day Tab and open?'
            }

            if (this.modalType === 'NOT_READY'){
                return 'One or more selected orders are not ready. Please ensure that all orders are ready.'
            }

            if (this.modalType === 'DELETE_DRIVER'){
                return 'Are you sure you want to delete this driver?'
            }

            if (this.modalType === 'REMOVE_DRIVER'){
                return 'Are you sure you want to remove this driver?'
            }

            if (this.modalType === 'DAY_CLOSED'){
                return 'Day Closed Successfully'
            }

            if (this.modalType === 'CLOSE_DAY'){
                return 'You are about to close the account for the day, press YES to proceed or NO to go back, this action cannot be undone.'
            }

            if (this.modalType === 'CLOSE_DAY_ERROR'){
                return 'Drivers must not have a route prior to closing day and must not have a balance, please wait and close out all drivers first.'
            }

            if (this.modalType === 'CLOSE_DAY_DRIVER_LEFT'){
                return 'Drivers must not have a route prior to closing day.'
            }

            if (this.modalType === 'CLOSE_DAY_OUTSTANDING_BALANCE'){
                return 'Drivers must not have an outstanding balance, close out all drivers prior to continuing.'
            }

            if (this.modalType === 'CLOSE_DRIVER'){
                return 'Are you sure you want to close this driver?'
            }

            if (this.modalType === 'NO_DRIVER_SELECTED'){
                return 'Please select a driver'
            }

            if (this.modalType === 'NOT_ALL_ORDERS_ARE_DELIVERED'){
                return 'You are attempting to return a driver before all orders have been marked delivered, click "Yes" to mark all orders delivered and return the driver or "No" to void this action'
            }

            if(this.modalType === 'NO_ORDERS_SELECTED'){
                return 'No orders are selected, please select the orders you wish to assign to the driver'
            }

            if(this.modalType === 'OPEN_PICKUP_ORDERS'){
                return 'You are attempting to close the day and archive all completed orders! One or more pick up orders are not marked completed yet, you could choose to mark those orders complete or leave them open for the next day'
            }

            if (this.modalType === 'NO_ORDER_SELECTED_FOR_VIRTUAL_DRIVERS'){
                return 'Please select an order to request an outsourced driver'
            }

            if (this.modalType === 'MANY_ORDERS_SELECTED_FOR_VIRTUAL_DRIVERS'){
                return 'Please choose one order at a time when requesting an outsourced driver'
            }
            
        },
        orderToDelete: function() {
            return this.$store.state.orders.selectedOrders[0];
        },
        ordersToAssign: function () {
            return this.$store.state.orders.selectedOrders;
        },
        selectedDriver: function () {
            return this.$store.getters["drivers/selectedDriver"];
        },
        driverToDelete: function () {
            return this.$store.state.drivers.driverToDelete;
        },

    },
    methods: {
        isVisible(button) {
            if(button.text == "Ok") {
                return button.conditions.some(condition => this.modalType === condition);
            }
            return !button.conditions.some(condition => this.modalType === condition);
        },
        togglePromptModal: function () {
            this.$store.dispatch("app/togglePromptModal");
        },
        markPickupOrdersComplete: function() {
            var par = {
                transactionType: "CloseDay",
                amount: 0,
                completePickupOrders: true
            };

            this.$store.dispatch("orders/closeStore", par);
            return;
        },
        markOrderAsVoid: function() {
            this.$store.dispatch("orders/markOrderAsVoid", this.orderToDelete);
        },
        closeStore: function() {
            var par = {
                transactionType: "CloseDay",
                amount: 0,
                completePickupOrders: false
            };

            this.$store.dispatch("orders/closeStore", par);
            return;
        },
        confirmPrompt: function () {
            if (this.modalType === 'REMOVE_ROUTE'){
                return this.$emit('removeRoute')
            }

            if (this.modalType === 'UNASSIGN_ORDER'){
                return this.$store.dispatch("orders/unassignOrders", this.ordersToAssign);
            }

            if (this.modalType === 'ASSIGN_ORDER_LEFT' || this.modalType === 'ASSIGN_ORDER_DRIVER_LEFT'){
                return this.$store.dispatch("orders/assignOrders", {
                    driver: this.selectedDriver, 
                    orders: this.ordersToAssign 
                });
            }

            if (this.modalType === 'DELETE_ORDER'){
                return this.$store.dispatch("orders/deleteOrder", this.orderToDelete);
            }

            if (this.modalType === 'NOT_OPEN'){
                this.$store.dispatch('app/setPanel', 'dayPanel');
                return this.togglePromptModal();
            }

            if (this.modalType === 'NOT_READY'){
                return this.togglePromptModal();
            }

            if (this.modalType === 'DELETE_DRIVER'){
                return this.$store.dispatch("drivers/deleteDriver", this.selectedDriver);
            }

            if (this.modalType === 'REMOVE_DRIVER'){
                return this.$emit('removeDriver')
            }

            if (this.modalType === 'DAY_CLOSED'){
                return this.togglePromptModal();
            }

            if (this.modalType === 'CLOSE_DAY'){
                var par = {
                    transactionType: "CloseDay",
                    amount: 0,
                };

                this.$store.dispatch("orders/closeStore", par);
                return;
            }
            
            if (this.modalType === 'CLOSE_DAY_ERROR' || this.modalType === 'CLOSE_DAY_DRIVER_LEFT' || this.modalType == 'CLOSE_DAY_OUTSTANDING_BALANCE'){
                return this.togglePromptModal();
            }

            if (this.modalType === 'CLOSE_DRIVER'){
                this.$store.dispatch('drivers/closeDriver');
                return 
            }

            if (this.modalType === 'NO_DRIVER_SELECTED'){
                return this.togglePromptModal();
            }

            if (this.modalType === 'NOT_ALL_ORDERS_ARE_DELIVERED'){
                return this.$emit('returnDriver')
            }
        },
    },
  };
  </script>
        
  <style scoped>
    .prompt-header{
        font-size: 2rem;
        font-weight: 600;
    }

    .prompt-body{
        font-size: 1.3rem;
        text-align: center;
    }

    .prompt-footer{
        width: 100%;
    }
    .prompt-footer > a{
        float: right;
    }

    .prompt-footer > a.is-danger{
        margin-right: 1em;
    }

    .prompt-footer > a.mr-1{
        margin-right: 1em;
    }

  </style>