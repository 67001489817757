<template>
<div>
    <spinner /> 
    <div v-if="configRequired">
        <config-modal></config-modal>
    </div>
    <div class="app-content" :style="{ zoom: zoomLevel, padding: isTabletMode ? '.4em' : '0'  }">
        <div class="panelWrapper">
            <settings-button-panel 
                class="leftPanel" 
                :class="{ 'right-shadow': currentPanel === 'map', 'disabled': isLocked }"
                :disabled="isLocked">
            </settings-button-panel>

            <div class="transition" :class="leftComponentClass">
                <!-- <settings-button-panel 
                    v-if="isTabletMode"
                    :class="{ 'disabled': isLocked }"
                    :disabled="isLocked">
                </settings-button-panel> -->
                <div>
                    <settings-panel v-if="currentPanel == 'settingsPanel'"></settings-panel>
                    <account-panel v-if="currentPanel == 'accountPanel'"></account-panel>
                    <day-panel v-if="currentPanel == 'dayPanel'"></day-panel>
                    <order-panel v-if="currentPanel == 'order'"></order-panel>
                    <dialpad v-if="currentPanel == 'dialpad'"></dialpad>
                    <new-order v-if="currentPanel == 'newOrder'"></new-order>
                    <edit-order v-if="currentPanel == 'editOrder'"></edit-order>
                    <store-Dial-Pad v-if="currentPanel=='storeDialPad'"></store-Dial-Pad>
                    <driver-Dial-Pad v-if="currentPanel=='driverDialPad'"></driver-Dial-Pad>
                    <driver-info-panel v-if="currentPanel == 'driverInfoPanel'"></driver-info-panel>
                </div>
                <map-panel v-if="currentPanel == 'map'"></map-panel>
                <!-- <bottom-menu
                v-if="isTabletMode"
                    :class="{ 'disabled': isLocked }"
                    :disabled="isLocked"
                ></bottom-menu> -->
            </div>
    
            <div class="transition" v-if="!isTabletMode"
                :class="rightComponentClass" 
                :style="{ padding: !isTabletMode ? '.3em' : '0'  }">
                <driver-panel 
                    :class="{ 'disabled': isLocked }" 
                    :disabled="isLocked">
                </driver-panel>
            </div>

        </div>
    </div>
</div>
</template>

<script>
// Load Components
var orderPanel = require("../orders/order_panel.vue").default;
var dialpad = require("../add_edit_order/dialpad.vue").default;
var newOrder = require("../add_edit_order/new_order.vue").default;
var editOrder = require("../add_edit_order/edit_order.vue").default;
var driverPanel = require("../drivers/driver_panel.vue").default;
var driverInfoPanel = require("../driver_info/driver_info_panel.vue").default;
var settingsPanel = require("../settings/settings_panel.vue").default;
var settingsButtonPanel = require("./settings_button_panel/index.vue").default;
var panelSelector = require("./panel_selector.vue").default;
var mapPanel = require("../maps/map_panel.vue").default;
var accountPanel = require("../accounts/account_info.vue").default;
var storeDialPad = require("../accounts/store_dialpad.vue").default;
var driverDialPad = require("../accounts/driver_dialpad.vue").default;
var configModal = require("../config_modal.vue").default;
var dayPanel = require("../accounts/day.vue").default;
var bottomMenu = require("./bottom-menu.vue").default;

const custom = require("../../js/custom.js");
var spinner = require("../spinner.vue").default;

module.exports = {
    components: {
        orderPanel,
        driverPanel,
        driverInfoPanel,
        settingsButtonPanel,
        panelSelector,
        dialpad,
        newOrder,
        editOrder,
        settingsPanel,
        mapPanel,
        accountPanel,
        dayPanel,
        storeDialPad,
        driverDialPad,
        configModal,
        spinner,
        bottomMenu
    },
    data: function () {
        return {
            configRequired: false,
            isTabletMode: false,
            zoomLevel: '100%',
        };
    },
    computed: {
        currentPanel: function () {
            return this.$store.getters["app/getCurrentPanel"];
        },
        leftComponentClass: function () {
            switch (this.expandLevel) {
                case 2:
                    return !this.isTabletMode ? "middlePanel left-larger" : "middlePanel middlePanel-tabletMode left-larger"
                default:
                    return !this.isTabletMode ? "middlePanel left-smaller" : "middlePanel middlePanel-tabletMode left-smaller"
            }
        },
        rightComponentClass: function () {
            switch (this.expandLevel) {
                case 1: 
                    return "rightPanel"
                case 2:
                    return "rightPanel right-smaller"
                default:
                    return "rightPanel right-larger" + (this.currentPanel == "map" ? " left-shadow" : "") + (this.isTabletMode ? " tablet-mode-padding" : "")
            }
        },
        isLocked: function () {
            return this.$store.getters["app/getIsLocked"];
        },
        storeInfo: function () {
            return this.$store.getters["orders/getStore"]
        },
        expandLevel: function() {
            return this.$store.getters["app/getExpandLevel"]
        },
    },
    created: function () {
        this.$store.dispatch("user/checkLSforUser");
        setTimeout(() => {
            this.checkConfig()
        }, 3000);

    },
    mounted() {
        this.checkTabletMode();
        window.addEventListener('resize', this.checkTabletMode);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.checkTabletMode);
    },
    methods: {
        checkTabletMode() {
            this.isTabletMode = custom.checkIfTablet();
        },
        toggleExpand: function () {
            // this.$store.dispatch("app/toggleExpand", this.isTabletMode);
        },
        logout: function () {
            this.$store.dispatch("logout");
        },
        checkConfig: function () {
            const storeInfo = this.$store.state.orders.store;
            const storeSettings = this.$store.state.settings;
            if (storeInfo.address == null || storeInfo.address == "") {
                this.$store.dispatch("app/lockOnPanel", 'settingsPanel');
            }

            if(storeInfo.address != null && storeInfo.address != '' && !storeSettings.firstDriverClockedIn){
                this.$store.dispatch("app/lockOnPanel", 'driverInfoPanel');
            }
        }
    },
    watch: {
        isTabletMode(tabletMode) {
            if (tabletMode !== undefined) {
                if (tabletMode) {
                    // this.$store.dispatch("app/changeExpandLevel", 1);
                    // this.zoomLevel = "75%";
                }
                else{
                    // this.zoomLevel = "100%"
                    // this.$store.dispatch("app/changeExpandLevel", 3);
                }
            }
        },
    }
};
</script>

<style scoped>
.scrollable-tablet-mode {
    overflow-y: auto;
    max-height: 650px;
    -webkit-overflow-scrolling: touch;
} 

.app-content {
    overflow: hidden !important;
    height: 100vh;
}

.panelWrapper{
    width: 100%;
    height: 100vh;
    display: flex;
}

.rightPanel{
    padding: 1.4em 1em 1em 1em !important;
    background-color: white;
}

.tablet-mode-padding{
    padding: 0 .1em .7em .5em !important;
}

.leftPanel {
    width: 120px;
    padding: .8em;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
}

.middlePanel{
    height: 100vh;
    width: 100%;
}

.card-height {
    height: 45rem !important;
}

.title-align {
    text-align: center;
    margin-top: 7rem;
    margin-bottom: 7rem !important;
}

.fields-position {
    margin-bottom: 4rem !important;
}

.fields {
    font-size: 1.25em !important;
    height: 2.5em !important;
}

.button-position {
    margin-top: 10rem;
}

.config-column {
    position: absolute;
    z-index: 9999;
    left: 11rem;
    top: 1rem;
    opacity: 1;
}

.backdrop {
    position: absolute;
    z-index: 999;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: black;
    opacity: 0.5;
}

.position-skip {
    float: right !important;
}

.ml-4 {
    margin-left: 4px;
}

.mr-3{
  margin-right: .4rem;
}

.transition{
    transition: 0.8s;
}

.right-smaller{
    width: 20%;
}

.right-larger{
    width: 30%;
} 

#overlay-div {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 999;
  background-color: white;
  padding: .7rem;
}

.left-shadow{
    box-shadow: -8px 8px 16px rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.right-shadow{
    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    background-color: white;
    margin-bottom: 0px !important;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}
</style>
